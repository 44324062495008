import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import * as SS from "../style/Sidestyle";
import notify from "../assets/notification_icon_2.png";
import { useSelector } from "react-redux";
import axios from "../utils/axios";
import edit from "../assets/edit.png";
import moment from "moment/moment";

// Sample notifications data


const NotificationIcon = styled.div`
  position: relative;
  cursor: pointer;
`;

const Dropdown = styled.div`
  position: absolute;
  top: 50px;
  padding: 10px 15px;
  right: -125px;
  min-width: 350px;
  max-width: 500px;
  max-height: 400px;
  overflow-y: auto;
  background: rgba(27, 27, 27, 1);
  /* border: 1px solid #ccc; */
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  &::-webkit-scrollbar {
    display: none;
  }
  /* &.dropdown-remove-scrollbar {
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &.dropdown-scrollbar {
    &::-webkit-scrollbar {
      display: none;
      width: 0;
      height: 0;
    }

    &:hover::-webkit-scrollbar {
      display: block;
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }
  } */
`;

const NotificationItem = styled.div`
  padding: 10px 0;
  border-bottom: 1px solid rgba(77, 76, 76, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  /* &:hover {
    background-color: #f7eded;
    
  } */
`;

const NotificationMessage = styled.div`
  flex: 1;
  color: #fff;
  font-size: 18px;
`;

const NotificationDate = styled.div`
  font-size: 15px;
  color: rgba(189, 189, 189, 1);
  /* margin-left: 10px; */
`;

const ReadMark = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${(props) => (props.read ? "green" : "red")};
  border-radius: 50%;
  margin-left: 10px;
`;

const NotificationDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [Data, setData] = useState([]);
  const dropdownRef = useRef(null);
  const parentRef = useRef(null);
  const UserData = useSelector((state) => state.userData);
  console.log(UserData);

  // const toggleDropdown = () => {
  //   setIsOpen(!isOpen);
  //   console.log(isOpen,"clicked");
  // };

  const toggleDropdown = () => {
    setIsOpen((prevState) => {
      console.log(!prevState, "clicked");
      return !prevState;
    });
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !parentRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  const handleScroll = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      window.addEventListener("scroll", handleScroll);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isOpen]);

  const handleMouseEnter = () => {
    dropdownRef.current.classList.remove("dropdown-remove-scrollbar");
    dropdownRef.current.classList.add("dropdown-scrollbar");
  };

  const handleMouseLeave = () => {
    dropdownRef.current.classList.remove("dropdown-scrollbar");
    dropdownRef.current.classList.add("dropdown-remove-scrollbar");
  };
  function formatDate(dateString) {
    const date = new Date(dateString);

    const pad = (num) => num.toString().padStart(2, "0");

    const day = pad(date.getDate());
    const month = pad(date.getMonth() + 1); // Months are zero-based
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = pad(date.getMinutes());
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    const strHours = pad(hours);

    return `${day}-${month}-${year} ${strHours}:${minutes} ${ampm}`;
  }

  useEffect(() => {
    const getNotification = async () => {
      try {
        const resp = await axios.get(`/get_notifications`);
        console.log(resp);
        const data = resp?.data?.data?.map((e) => ({
          id: e._id,
          message: e.message,
          date: moment(e.createdAt).format('DD-MM-YYYY hh:mm A'),
          read: e.isRead,
        }));
        setData(data);
      } catch (error) {
        console.log(error);
      }
    };

    getNotification();
  }, [isOpen]);

  const markedAsRead = async () => {
    try {
      const resp = await axios.post("/mark_notifications_as_read");
      setIsOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <NotificationIcon>
      {/* <div onClick={toggleDropdown}>
        <img src="notification-icon.png" alt="Notifications" />
      </div> */}
      <SS.Roundbox
        onClick={toggleDropdown}
        ref={parentRef}
        style={{ position: "relative" }}
      >
        <img
          src={notify}
          alt="nav_icon"
          style={{ width: "25px", height: "25px" }}
        />
        {/* <div class="notification-badge">4</div> */}
      </SS.Roundbox>
      {isOpen && (
        <Dropdown
          ref={dropdownRef}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <NotificationItem
            style={{
              justifyContent: "right",
              fontSize: "12px",
              cursor: "default",
            }}
          >
            <div
              className="d-flex"
              style={{ cursor: "pointer" }}
              onClick={() => {
                markedAsRead();
              }}
            >
              <div
                style={{ width: "15px", height: "15px", marginRight: "5px" }}
              >
                <img
                  src={edit}
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </div>
              Mark all as read
            </div>
          </NotificationItem>
          {Data.length > 0 ? (
            Data.map((notification) => (
              <NotificationItem key={notification.id}>
                <div className="d-flex align-items-center justify-content-between w-100">
                  <div
                    className="d-flex flex-column"
                    style={{ width: "calc(100% - 10px)" }}
                  >
                    <NotificationMessage>
                      {notification.message}
                    </NotificationMessage>
                    <NotificationDate>{notification.date}</NotificationDate>
                  </div>
                  <ReadMark read={notification.read} />
                </div>
              </NotificationItem>
            ))
          ) : (
            <NotificationItem style={{ height: "100%" }}>
              <div className="d-flex align-items-center justify-content-center w-100">
                <div className="text-white">No notifications</div>
              </div>
            </NotificationItem>
          )}
        </Dropdown>
      )}
    </NotificationIcon>
  );
};

export default NotificationDropdown;
