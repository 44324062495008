import React, { useEffect, useRef, useState } from "react";
import * as SS from "./SidebarStyle";
import * as GS from "../../style/LayoutStyle";
import jeepLogo from "../../assets/vectorLogo.png";
// import jeepLogo from "../../assets/Jeep_Logo.png";
import role_manage_dark from "../../assets/sidebar_icons/active/Manage Access.png";
import role_manage_light from "../../assets/sidebar_icons/inactive/Manage Access.png";
import brand_manage_dark from "../../assets/sidebar_icons/active/Brand Management.png";
import brand_manage_light from "../../assets/sidebar_icons/inactive/Brand Management.png";
import link_dark from "../../assets/sidebar_icons/active/Links.png";
import link_light from "../../assets/sidebar_icons/inactive/Links.png";
import file_manage_dark from "../../assets/sidebar/file_manage(dark).png";
import file_manage_light from "../../assets/sidebar/file_manage(light).png";
import agency_dark from "../../assets/sidebar_icons/active/Agency Management.png";
import agency_light from "../../assets/sidebar_icons/inactive/Agency Management.png";
import dash_dark from "../../assets/sidebar_icons/active/dashboard.png";
import dash_light from "../../assets/sidebar_icons/inactive/Dashboard.png";
import Trash_dark from "../../assets/sidebar_icons/active/Trash.png";
import Trash_light from "../../assets/sidebar_icons/inactive/trash.png";
import dealer_log_dark from "../../assets/sidebar_icons/active/Dealer Logs.png";
import dealer_log_light from "../../assets/sidebar_icons/inactive/Dealer Logs.png";
import sideImage from "../../assets/sidebar/sidebar_image.png";
import signoutdark from "../../assets/sidebar/sign-out.png";
import signoutlight from "../../assets/sidebar_icons/inactive/Sign Out.png";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../store/action";
import axios from "../../utils/axios";
import { nanoid } from "nanoid";
import SimpleSlider from "../DashboardSlick/DashboardSlick";

const ignoredPermissions = ["User Profile"];

// const filterMenuItemsByPermission = (menuItems, permissions) => {
//   const permissionNames = permissions.map((p) => p.name);

//   const filteredMenuItems = menuItems
//     .map((item) => {
//       if (item.subItems) {
//         const filteredSubItems = item.subItems.filter((subItem) =>
//           permissionNames.includes(subItem.permissionKey)
//         );
//         if (filteredSubItems.length > 0) {
//           return { ...item, subItems: filteredSubItems };
//         }
//       } else if (permissionNames.includes(item.permissionKey)) {
//         return item;
//       }
//       return null;
//     })
//     .filter((item) => item !== null);

//   return filteredMenuItems;
// };

const menuItems = [
  {
    title: "Dashboard",
    path: "/adminPanel/home",
    permissionKey: "Dashboard",
    icon: {
      light: dash_light,
      dark: dash_dark,
    },
    active: ["/adminPanel/home"],
  },
  {
    title: "Manage Access",
    icon: {
      light: role_manage_light,
      dark: role_manage_dark,
    },
    permissionKey: "Access Module",
    active: [
      "/adminPanel/add-user",
      "/adminPanel/create-user",
      "/adminPanel/add-role",
      "/adminPanel/create-role",
      "/adminPanel/edit-user/:id",
      "/adminPanel/edit-role/:id",
    ],
    subItems: [
      {
        title: "Add Role",
        permissionKey: "Add Role Manage Access",
        path: "/adminPanel/add-role",
      },
      {
        title: "Add User",
        permissionKey: "Add User Manage Access",
        path: "/adminPanel/add-user",
      },
    ],
  },
  {
    title: "Brand Management",
    permissionKey: "Brand Management",
    icon: {
      light: brand_manage_light,
      dark: brand_manage_dark,
    },
    active: [
      "/adminPanel/jeep-brand",
      "/adminPanel/citroen-brand",
      "/adminPanel/add-jeep-brand",
      "/adminPanel/add-citroen-brand",
      "/adminPanel/product-category-test/:id/:type",
      "/adminPanel/jeep-files/:prodId/:catId",
      "/adminPanel/citroen-files/:prodId/:catId",
      "/adminPanel/upload-citroen-files/:prodId/:catId",
    ],
    subItems: [
      {
        title: "Vector3",
        permissionKey: "Jeep Brand Management",
        path: "/adminPanel/jeep-brand",
      },
      {
        title: "Ikokas",
        permissionKey: "Citroen Brand Management",
        path: "/adminPanel/citroen-brand",
      },
    ],
  },
  {
    title: "Dealer Management",
    permissionKey: "Dealer Management",
    icon: {
      light: role_manage_light,
      dark: role_manage_dark,
    },
    active: [
      "/adminPanel/dealer_management",
      "/adminPanel/jeep",
      "/adminPanel/citroen",
      "/adminPanel/dealer-citroen",
      "/adminPanel/add-jeep-dealer",
      "/adminPanel/edit-jeep-dealer/:id",
      "/adminPanel/edit-citroen-dealer/:id",
      "/adminPanel/add-citroen-dealer",
    ],
    subItems: [
      {
        title: "Vector3",
        permissionKey: "Jeep Dealer Management",
        path: "/adminPanel/dealer_management",
      },
      {
        title: "Ikokas",
        permissionKey: "Citroen Dealer Management",
        path: "/adminPanel/dealer-citroen",
      },
    ],
  },
  {
    title: "Agency Management",
    icon: {
      light: agency_light,
      dark: agency_dark,
    },
    permissionKey: "Agency Management",
    active: [
      "/adminPanel/add-agency",
      "/adminPanel/add-agency-user",
      "/adminPanel/file-uploads",
      "/adminPanel/create-agency-user",
      "/adminPanel/create-agency",
      "/adminPanel/edit-agency/:id",
      "/adminPanel/edit-agency-user/:id",
    ],
    subItems: [
      {
        title: "Add Agency",
        permissionKey: "Add Agency Agency Management",
        path: "/adminPanel/add-agency",
      },
      {
        title: "Add User",
        permissionKey: "Add User Agency Management",
        path: "/adminPanel/add-agency-user",
      },
    ],
  },
  {
    title: "Trash",
    permissionKey: "Trash Management",
    icon: {
      light: Trash_light,
      dark: Trash_dark,
    },
    active: [
      "/adminPanel/jeep-brand-files",
      "/adminPanel/citroen-trash",
      "/adminPanel/citroen-brand-files",
      "/adminPanel/jeep-category-files/:id",
      "/adminPanel/citroen-category-files/:id",
      "/adminPanel/jeep-trash-files/:prodId/:catId",
      "/adminPanel/citroen-trash-files/:prodId/:catId",
    ],
    subItems: [
      {
        title: "Vector3",
        permissionKey: "Jeep Trash Management",
        path: "/adminPanel/jeep-brand-files",
      },
      {
        title: "Ikokas",
        permissionKey: "Citroen Trash Management",
        path: "/adminPanel/citroen-brand-files",
      },
    ],
  },
  {
    title: "Dealer Logs",
    permissionKey: "Dealer Logs",
    icon: {
      light: dealer_log_light,
      dark: dealer_log_dark,
    },
    active: [
      "/adminPanel/jeep-dealer-logs",
      "/adminPanel/citroen-dealer-logs",
      "/adminPanel/jeep-detail-logs/:id",
      "/adminPanel/citroen-detail-logs/:id",
    ],
    subItems: [
      {
        title: "Vector3",
        permissionKey: "Jeep Dealer Logs",
        path: "/adminPanel/jeep-dealer-logs",
      },
      {
        title: "Ikokas",
        permissionKey: "Citroen Dealer Logs",
        path: "/adminPanel/citroen-dealer-logs",
      },
    ],
  },
  {
    title: "Links",
    icon: {
      light: link_light,
      dark: link_dark,
    },
    permissionKey: "Links",
    active: ["/adminPanel/jeep-links", "/adminPanel/citroen-links"],
    subItems: [
      {
        title: "Vector3",
        permissionKey: "Jeep Links",
        path: "/adminPanel/jeep-links",
      },
      {
        title: "Ikokas",
        permissionKey: "Citroen Links",
        path: "/adminPanel/citroen-links",
      },
    ],
  },
  {
    title: "Profile",
    path: "/adminPanel/user-profile",
    permissionKey: "User Profile",
    icon: {
      light: role_manage_light,
      dark: role_manage_dark,
    },
    active: ["/adminPanel/user-profile"],
  },
];

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData);
  const [filteredMenuItems, setFilteredMenuItems] = useState([]);
  console.log(userData);
  // console.log(userData.permission.permission);
  // const [openStates, setOpenStates] = useState({});
  // useEffect(() => {
  //   const alwaysIncludeKeys = ["Dashboard", "Links", "User Profile"];

  //   // const filteredItems = menuItems.filter(item =>
  //   //   userData.permission.permission.some(permission => permission.main_tab === item.permissionKey)
  //   // );
  //   if (!userData.isSuperAdmin) {
  //     const filteredItems = menuItems.filter(
  //       (item) =>
  //         alwaysIncludeKeys.includes(item.permissionKey) ||
  //         userData.permission.permission.some(
  //           (permission) => permission.main_tab === item.permissionKey
  //         )
  //     );
  //     console.log(filteredItems);
  //     setFilteredMenuItems(filteredItems);
  //   } else {
  //     setFilteredMenuItems(menuItems);
  //   }
  // }, [userData]);
  // useEffect(() => {
  //   const alwaysIncludeKeys = ["Dashboard", "Links", "User Profile"];

  //   if (!userData.isSuperAdmin) {
  //     const filteredItems = menuItems.filter((item) => {
  //       if (alwaysIncludeKeys.includes(item.permissionKey)) {
  //         return true;
  //       }

  //       // const mainTabPermission = userData.permission.permission.find(
  //       //   (permission) => permission.name === item.subItems.permissionKey
  //       // );
  //       // const mainTabPermission = userData.permission.permission.find(
  //       //   (permission) => item.subItems.includes(permission.name)
  //       // );

  //       // if (!mainTabPermission) {
  //       //   return false;
  //       // }

  //       // if (item.subItems) {
  //       //   const filteredSubItems = item.subItems.filter((subItem) => {
  //       //     return userData.permission.permission.some(
  //       //       (innerTab) => innerTab === subItem.permissionKey
  //       //     );
  //       //   });
  //       //   item.subItems = filteredSubItems;
  //       // }

  //       // return true;
  //       // if(item.permissionKey === "Manage Access"){
  //       //   if(userData?.permission.permission?.includes("Add Role Manage Access")){

  //       //   }
  //       // }
  //     });

  //     setFilteredMenuItems(filteredItems);
  //   } else {
  //     setFilteredMenuItems(menuItems);
  //   }
  //   // setFilteredMenuItems(menuItems);
  // }, [userData]);

  const filterMenuItemsByPermission = (
    menuItems,
    permissions,
    ignoredPermissions
  ) => {
    if (!userData.isSuperAdmin) {
      const permissionNames = permissions.map((p) => p.name);
      const filteredMenuItems = menuItems
        .map((item) => {
          if (ignoredPermissions.includes(item.permissionKey)) {
            return item; // Include as-is if the item is in ignoredPermissions
          }
          if (item.permissionKey === "Dashboard") {
            const hasJeepDashboard = permissionNames.includes("Jeep Dashboard");
            const hasCitroenDashboard =
              permissionNames.includes("Citroen Dashboard");
            if (hasJeepDashboard || hasCitroenDashboard) {
              return item;
            }
            return null; // Exclude Dashboard if neither permissions are present
          }

          if (item.subItems) {
            const filteredSubItems = item.subItems.filter((subItem) =>
              permissionNames.includes(subItem.permissionKey)
            );
            if (filteredSubItems.length > 0) {
              return { ...item, subItems: filteredSubItems };
            }
          } else if (permissionNames.includes(item.permissionKey)) {
            return item;
          }
          return null;
        })
        .filter((item) => item !== null);

      return filteredMenuItems;
    } else {
      return menuItems;
    }
  };

  useEffect(() => {
    if (!userData.isSuperAdmin) {
      const filteredMenuItems = filterMenuItemsByPermission(
        menuItems,
        userData?.permission.permission,
        ignoredPermissions
      );
      setFilteredMenuItems(filteredMenuItems);
    } else {
      setFilteredMenuItems(menuItems);
    }
  }, [userData]);

  const [openIndex, setOpenIndex] = useState(null);
  const dropdownRefs = useRef({});
  const [showScrollbar, setShowScrollbar] = useState(false);
  // const toggleSection = (index) => {
  //   setOpenStates((prevStates) => ({
  //     ...prevStates,
  //     [index]: !prevStates[index],
  //   }));
  // };
  const toggleSection = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  // useEffect(() => {
  //   Object.entries(dropdownRefs.current).forEach(([key, ref]) => {
  //     if (ref) {
  //       if (openStates[key]) {
  //         ref.style.height = `${ref.scrollHeight}px`;
  //       } else {
  //         ref.style.height = "0px";
  //       }
  //     }
  //   });
  // }, [openStates]);
  useEffect(() => {
    Object.entries(dropdownRefs.current).forEach(([key, ref]) => {
      if (ref) {
        if (parseInt(key) === openIndex) {
          ref.style.height = `${ref.scrollHeight}px`;
        } else {
          ref.style.height = "0px";
        }
      }
    });
  }, [openIndex]);

  // const isActive = (activePaths) => {
  //   return activePaths
  //     ? activePaths.some((path) => path === location.pathname)
  //     : false;
  // };
  const isActive = (activePaths) => {
    const { pathname } = location;

    return activePaths
      ? activePaths.some((path) => {
          // Check for exact path match
          if (path === pathname) return true;

          // Check for dynamic routes with :id
          const regex = new RegExp(`^${path.replace(/:[^\s/]+/g, "[^/]+")}$`);
          return regex.test(pathname);
        })
      : false;
  };

  const handleLogout = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Logout!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes Logout!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const resp = await axios.get("/logout");
          dispatch(setUserData(null));
          localStorage.clear();
          navigate("/");
          window.location.reload();
          console.log(resp, "logout");
          toast.success(resp.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        } catch (error) {
          toast.error(
            error.response.data.message ||
              error.data.message ||
              "Error Ocuured while logging out..!",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
            }
          );
        }
      }
    });
  };

  useEffect(() => {
    const sidebarElement = document.getElementById("sidebar-scroll-state");

    const handleMouseEnter = () => {
      setShowScrollbar(true);
    };

    const handleMouseLeave = () => {
      setShowScrollbar(false);
    };

    if (sidebarElement) {
      sidebarElement.addEventListener("mouseenter", handleMouseEnter);
      sidebarElement.addEventListener("mouseleave", handleMouseLeave);
    }

    return () => {
      if (sidebarElement) {
        sidebarElement.removeEventListener("mouseenter", handleMouseEnter);
        sidebarElement.removeEventListener("mouseleave", handleMouseLeave);
      }
    };
  }, []);

  return (
    <SS.SidebarBox>
      <SS.LogoBox>
        <GS.IconBox width={"100px"} height={"80px"}>
          <img src={jeepLogo} alt="" />
        </GS.IconBox>
      </SS.LogoBox>
      <SS.SidebarBodyContainer
        id="sidebar-scroll-state"
        style={{
          overflowY: showScrollbar ? "auto" : "hidden",
        }}
      >
        <SS.SidebarBody>
          {filteredMenuItems.map((item, index) => (
            <div key={`menu-item-${index}`}>
              <SS.SidebarItem
                onClick={() => toggleSection(index)}
                isActive={isActive(item?.active)}
              >
                <GS.IconBox width={"20px"} height={"20px"}>
                  {isActive(item?.active) ? (
                    <img src={item.icon.dark} alt={item.title} />
                  ) : (
                    <img src={item.icon.light} alt={item.title} />
                  )}
                </GS.IconBox>
                {item?.subItems && item?.subItems?.length > 0 ? (
                  <div className="item-label">{item.title}</div>
                ) : (
                  <>
                    <NavLink
                      style={{ textDecoration: "none", color: "inherit" }}
                      to={item?.path}
                    >
                      <div className="item-label">{item.title}</div>
                    </NavLink>
                  </>
                )}
              </SS.SidebarItem>
              {item?.subItems && item?.subItems?.length > 0 ? (
                <>
                  <SS.DropdownContainer
                    ref={(el) => (dropdownRefs.current[index] = el)}
                    // isOpen={openStates[index]}
                    isOpen={openIndex === index}
                  >
                    {item.subItems.map((subItem, subIndex) => (
                      <SS.SubItem key={`sub-item-${subIndex}`}>
                        <NavLink
                          to={subItem.path}
                          className="subitem-link"
                          activeClassName="active"
                        >
                          {subItem.title}
                        </NavLink>
                      </SS.SubItem>
                    ))}
                  </SS.DropdownContainer>
                </>
              ) : (
                ""
              )}
            </div>
          ))}
          <SS.SidebarItemLogout
            key={nanoid()}
            onClick={() => {
              handleLogout();
            }}
          >
            <GS.IconBox width={"20px"} height={"20px"}>
              <img src={signoutlight} alt="" />
            </GS.IconBox>
            <div className="item-label">Logout</div>
          </SS.SidebarItemLogout>
        </SS.SidebarBody>
        {/* <SS.SidebarBody>
        <SS.SidebarItem>
            <GS.IconBox width={"20px"} height={"20px"}>
                <img src={role_manage} alt=''/>
            </GS.IconBox>
            <div className='item-label'>Role Management</div>
        </SS.SidebarItem>
        <SS.SidebarItem>
            <GS.IconBox width={"20px"} height={"20px"}>
                <img src={role_manage} alt=''/>
            </GS.IconBox>
            <div className='item-label'>Role Management</div>
        </SS.SidebarItem>
        <SS.SidebarItem>
            <GS.IconBox width={"20px"} height={"20px"}>
                <img src={role_manage} alt=''/>
            </GS.IconBox>
            <div className='item-label'>Role Management</div>
        </SS.SidebarItem>
      </SS.SidebarBody> */}

        <SS.SidebarLogoImage
          style={{
            borderRight: showScrollbar ? "none" : "7px solid transparent",
          }}
        >
          <GS.IconBox width={"100%"} height={"200px"}>
            {/* <img src={sideImage} alt="" /> */}
            <SimpleSlider />
          </GS.IconBox>
        </SS.SidebarLogoImage>
      </SS.SidebarBodyContainer>
    </SS.SidebarBox>
  );
};

export default Sidebar;
