import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slick1 from "../../assets/Dashboard/slick-1.png";
import slick2 from "../../assets/Dashboard/slick-2.png";
import slick3 from "../../assets/Dashboard/slick-3.png";
import slick4 from "../../assets/Dashboard/slick-4.png";

export default function SimpleSlider() {
  var settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    appendDots: (dots) => (
      <div
        style={{
          position: "relative",
          bottom: "10px",
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
  };
  return (
    <div style={{ width: "200px", margin: "0 auto" }}>
      <Slider {...settings}>
        <div>
          <img
            src={slick1}
            alt=""
            style={{ objectFit: "contain", width: "100%", height: "100%" }}
          />
        </div>
        <div>
          <img
            src={slick2}
            alt=""
            style={{ objectFit: "contain", width: "100%", height: "100%" }}
          />
        </div>
        <div>
          <img
            src={slick3}
            alt=""
            style={{ objectFit: "contain", width: "100%", height: "100%" }}
          />
        </div>
        <div>
          <img
            src={slick4}
            alt=""
            style={{ objectFit: "contain", width: "100%", height: "100%" }}
          />
        </div>
      </Slider>
    </div>
  );
}
